<template>
  <div class="navigation-title-section">
    <div class="navigation-title-section-content">
      <p v-if="!runUpdate">
        {{ $route.query.mode }} {{ $route.query.singular }}
      </p>
      <p v-else>Update {{ $route.query.singular }}</p>
    </div>
  </div>
  <div class="single-post">
    <div>
      <form @submit.prevent="">
        <div class="row">
          <div class="col-md-8 single_post_main_box">
            Title <span class="required-field">*</span>
            <el-input
              v-model="postData.title"
              required
              class="add-new-item__title"
              placeholder="Title"
            ></el-input>
            <span v-if="$route.query.singular === 'artwork'">Overview </span>
            <span v-if="$route.query.singular === 'artist'">About artist </span>
            <span v-if="$route.query.singular === 'technique'"
              >About technique</span
            >

            <tiptap v-model="postData.content" />
            <el-tabs
              v-if="$route.params.type == 'artworks'"
              type="border-card"
              class="mt-4"
              v-model="general"
              @tab-click="() => {}"
            >
              <el-tab-pane label="General" current-name="general">
                <general :postType="this.$route.params.type" />
              </el-tab-pane>
              <el-tab-pane label="Techniques" name="techniques">
                <techniques :postType="this.$route.params.type" />
              </el-tab-pane>
              <el-tab-pane label="Gallery" name="gallery">
                <gallery :postType="this.$route.params.type" />
              </el-tab-pane>
              <el-tab-pane label="Collector Trends" name="colectortrends">
                <collector-trends :postType="this.$route.params.type" />
              </el-tab-pane>
              <el-tab-pane label="Value Indicators" name="valueindicators">
                <value-indicators :postType="this.$route.params.type" />
              </el-tab-pane>
              <el-tab-pane label="SEO Settings" name="seosettings">
                <div style="max-width: 300px;">
                  <p class="mb-0">SEO Settings</p>
                  <small class="mb-5">Meta title and description</small>
                  <el-input v-model="postData.meta_title" class="add-new-item__title" placeholder="Meta Title"></el-input>
                  <el-input v-model="postData.meta_description" class="add-new-item__title" placeholder="Meta Description"></el-input>
                </div>
              </el-tab-pane>
              <el-tab-pane label="Feature suites image" name="featuresuitesimage">
                <ImageSelectorInput v-model="postData.suites_featured_image_id" :multiple="false" :featuredImageSlug="'empty'"
                  :pdfSelector="false"></ImageSelectorInput>
              </el-tab-pane>
            </el-tabs>
            <el-tabs
              v-else-if="$route.params.type == 'artists'"
              type="border-card"
              class="mt-4"
              v-model="general"
              @tab-click="() => {}"
            >
              <el-tab-pane label="Summary" current-name="shortdesc">
                <short-description
                  :postType="this.$route.params.type"
                ></short-description>
              </el-tab-pane>
              <el-tab-pane label="Biography" current-name="bio">
                <biography :postType="this.$route.params.type"></biography>
              </el-tab-pane>
              <el-tab-pane label="General" current-name="general">
                <artist-general
                  :postType="this.$route.params.type"
                ></artist-general>
              </el-tab-pane>
              <el-tab-pane label="Awards" current-name="awards">
                <awards :postType="this.$route.params.type"></awards>
              </el-tab-pane>
              <el-tab-pane label="Exhibitions" current-name="exhibitions">
                <exhibition :postType="this.$route.params.type"></exhibition>
              </el-tab-pane>
              <el-tab-pane
                label="Value Indicator"
                current-name="valueindicator"
              >
                <artist-value-indicator
                  :postType="this.$route.params.type"
                ></artist-value-indicator>
              </el-tab-pane>
            </el-tabs>
            <el-tabs
              v-else-if="$route.params.type == 'techniques'"
              type="border-card"
              class="mt-4"
              v-model="general"
              @tab-click="() => {}"
            >
              <el-tab-pane label="Principles" current-name="principles">
                <principles :postType="this.$route.params.type"></principles>
              </el-tab-pane>
              <el-tab-pane label="Artists" current-name="artists">
                <artists :postType="this.$route.params.type"></artists>
              </el-tab-pane>
              <!-- <el-tab-pane
                label="Value Indicator"
                current-name="valueindicator"
              >
                <artist-value-indicator
                  :postType="this.$route.params.type"
                ></artist-value-indicator>
              </el-tab-pane> -->
              <el-tab-pane label="Download PDF" current-name="pdf">
                <span>PDF</span>
                <ImageSelectorInput
                  v-model="postData.pdfId"
                  :multiple="false"
                  :featuredImageSlug="'empty'"
                  :pdfSelector="true"
                ></ImageSelectorInput>
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="col-md-4 single_post_sidebar">
            <div class="row">
              <div v-if="!runUpdate" class="col-md-12">
                <div class="buttons_section single_post_box">
                  <el-button class="button_style_1" @click="createPost">
                    Publish
                  </el-button>
                </div>
              </div>
              <div v-else class="col-md-12">
                <div class="buttons_section single_post_box">
                  <el-button class="button_style_1" @click="updatePost">
                    Update
                  </el-button>
                  <el-button class="button_style_1" @click="openInNewTab">
                    Preview
                  </el-button>
                </div>
              </div>
              <div class="col-md-12">
                <div class="col-xl-12">
                  <div class="post_status single_post_box">
                    <h3 class="single_post_box__h3">Status</h3>
                    <el-select v-model="postData.status" placeholder="Select">
                      <el-option
                        v-for="item in postData.statuses"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="col-xl-12">
                  <div class="post_status single_post_box">
                    <h3 class="single_post_box__h3">
                      Slug <span class="required-field">*</span>
                    </h3>
                    <el-input
                      v-model="postData.slug"
                      required
                      placeholder="Slug"
                      :value="postData.slug"
                    ></el-input>
                  </div>
                </div>
              </div>
              <div class="col-xl-12">
                <div class="single_post_box">
                  <h3 class="single_post_box__h3">Featured image</h3>
                  <ImageSelectorInput
                    v-model="postData.featuredImage"
                    :multiple="false"
                    :featuredImageSlug="'empty'"
                  ></ImageSelectorInput>
                </div>
              </div>
              <!-- <div v-if="$route.query.singular === 'technique'" class="col-xl-12">
                <SubTechnique :parentId="this.$route.params.id" />
              </div> -->
              <div v-if="$route.query.singular === 'artwork'" class="col-xl-12">
                <Tags
                  :primaryTagID="postData.primaryTagId"
                  :choosenTagS="postData.choosenTags"
                  :tag="postData.tags"
                  @onFavorite="addTagAsPrimary"
                  @onPropsChange="tagsPropsChange"
                  @onTagCreate="tagCreate"
                />
                <div v-if="customTaxonomiesRes.length > 0">
                  <div
                    v-for="customTax in customTaxonomiesRes"
                    :key="customTax.id"
                    class="single_post_box"
                  >
                    <tax
                      @onCreate="createTaxs"
                      @collectTaxs="collectTaxs"
                      :tax="customTax"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Tiptap from "../../../components/Tiptap/Tiptap";
import Tags from "./Tags.vue";
import {
  ElTabs,
  ElTabPane,
  ElSelect,
  ElOption,
  ElNotification,
  ElInput,
} from "element-plus";
import { updatePost } from "../../../services/updatePost";
import ImageSelectorInput from "../../../components/Tiptap/ImageSelectorInput";
import slugify from "slugify";
import General from "../../../components/cf/General.vue";
import Gallery from "../../../components/cf/Gallery.vue";
import ValueIndicators from "../../../components/cf/ValueIndicators.vue";
import { validateForm } from "../../../helpers/validator";
import { createPost } from "../../../services/createPost";
import CollectorTrends from "../../../components/cf/CollectorTrends.vue";
import Biography from "../../../components/cf/Biography.vue";
import Techniques from "../../../components/cf/Techniques.vue";
import ShortDescription from "../../../components/cf/ShortDescription.vue";
import ArtistGeneral from "../../../components/cf/ArtistGeneral.vue";
import Awards from "../../../components/cf/Awards.vue";
import Exhibition from "../../../components/cf/Exhibitions.vue";
import ArtistValueIndicator from "../../../components/cf/ArtistValueIndicator.vue";
import Principles from "../../../components/cf/Principles.vue";
import Artists from "../../../components/cf/Artists.vue";
import Tax from "./Tax.vue";
import SubTechnique from "./SubTechnique.vue";

export default {
  name: "New",
  components: {
    Tiptap,
    ImageSelectorInput,
    ElTabs,
    ElTabPane,
    ElSelect,
    ElOption,
    ElInput,
    General,
    Gallery,
    ValueIndicators,
    CollectorTrends,
    Techniques,
    Biography,
    Tax,
    ShortDescription,
    ArtistGeneral,
    Awards,
    Exhibition,
    ArtistValueIndicator,
    Principles,
    Artists,
    Tags,
    // SubTechnique
  },
  data() {
    return {
      postData: {
        title: "",
        slug: "",
        content: "",
        featuredImage: 0,
        statuses: [
          {
            id: 2,
            name: "publish",
          },
          {
            id: 1,
            name: "draft",
          },
        ],
        status: "publish",
        tags: [],
        choosenTags: [],
        primaryTagId: 0,
        pdfId: 0,
        meta_title: "",
        meta_description: "",
        suites_featured_image_id: 0,
      },
      customTaxonomies: [
        "collector-items",
        "colors",
        "types",
        "suits",
        "subject-matters",
      ],
      requiredTaxonomies: [],
      customTaxonomiesRes: [],
      choosenTaxonomies: [],
      choosenCollectorItems: [],
      runUpdate: false,
      lastId: 0,
    };
  },
  watch: {
    "postData.title": {
      handler: function (newVal, oldVal) {
        return (this.postData.slug = slugify(newVal.toLowerCase()));
      },
      deep: true,
    },
    "postData.choosenTags": {
      handler: function (newVal, oldVal) {
        if (!newVal.includes(this.postData.primaryTagId)) {
          this.postData.primaryTagId = 0;
        }
      },
    },
    $route(to, from) {
      this.postData.title = "";
      this.postData.slug = "";
      this.postData.content = "";
      this.postData.choosenTags = [];
      this.postData.primaryTagId = 0;
      this.postData.featuredImage = 0;
      if (this.$route.params.type === "artworks") {
        this.$api
          .get(`${process.env.VUE_APP_SHARED_API}api/tags?per_page=15`)
          .then((res) => {
            if (res.data.current_page) {
              this.postData.tags = res.data.data;
            }
          });
        this.customTaxonomies.map((tax, i) => {
          this.$api
            .get(`${process.env.VUE_APP_SHARED_API}api/${tax}?per_page=15`, {})
            .then((res) => {
              if (res.data.current_page) {
                this.customTaxonomiesRes.push({
                  title: tax,
                  id: i,
                  data: res.data.data,
                  choosenTax: [],
                });
              }
            });
        });
      }
    },
  },
  methods: {
    collectTaxs(value) {
      if (value.name !== "collector-items") {
        if (
          !this.choosenTaxonomies.some(
            (item) =>
              item.name.toLowerCase().replace(/\s/g, "").replace("-", "") ==
              value.name.toLowerCase().replace(/\s/g, "").replace("-", "")
          )
        ) {
          this.choosenTaxonomies.push(value);
        } else if (value.taxs.length > 0) {
          let foundIndex = this.choosenTaxonomies.findIndex(
            (x) =>
              x.name.toLowerCase().replace(/\s/g, "").replace("-", "") ==
              value.name.toLowerCase().replace(/\s/g, "").replace("-", "")
          );
          this.choosenTaxonomies[foundIndex] = value;
        }
      } else {
        this.choosenCollectorItems = value.taxs;
      }
    },
    async createPost() {
      if (this.postData.title === "" || this.postData.slug === "") {
        ElNotification({
          title: "Warning!",
          message: "Mandatory fields must be filled in!",
          type: "warning",
        });
      } else {
        this.$store.dispatch("collectData");
        let isValid =
          this.$route.params.type === "artworks"
            ? await validateForm(this.$route.params.type)
            : true;
        if (!isValid) {
          ElNotification({
            title: "Warning!",
            message: "Mandatory fields must be filled in!",
            type: "warning",
          });
        } else {
          const loader = this.$loading.show();
          return await createPost(
            this.$route.params.type,
            this.postData,
            this.choosenTaxonomies,
            this.choosenCollectorItems
          )
            .then((res) => {
              if (res.data?.id !== 0) {
                this.lastId = res.data.id;
                loader.hide();
                this.runUpdate = true;
                ElNotification({
                  title: "Success!",
                  message: `${this.postData.title} successfully created.`,
                  type: "success",
                });
                if (
                  this.$store.state.customFields.artworks
                    .collectorTrendsDeletedIds.length > 0
                ) {
                  this.$api.delete(
                    `${process.env.VUE_APP_SHARED_API}api/collector_trends/${this.lastId}`,
                    {
                      data: {
                        ids: this.$store.state.customFields.artworks
                          .collectorTrendsDeletedIds,
                      },
                    }
                  );
                }
                let localCollectorCreate = [];
                let localCollectorUpdate = [];
                this.$store.state.customFields.artworks.collectorTrends &&
                  this.$store.state.customFields.artworks.collectorTrends.map(
                    (collector) => {
                      if (collector.created_at) {
                        localCollectorUpdate.push(collector);
                      } else {
                        localCollectorCreate.push(collector);
                      }
                    }
                  );
                if (localCollectorCreate.length > 0) {
                  const clean = (obj) => {
                    for (var propName in obj) {
                      if (
                        obj[propName] === null ||
                        obj[propName] === undefined ||
                        obj[propName] === 0 ||
                        obj[propName] === ""
                      ) {
                        delete obj[propName];
                      }
                    }
                    return obj;
                  };
                  let requestArr = [];
                  localCollectorCreate.map((obj) => {
                    let o = clean(obj);
                    requestArr.push(o);
                  });
                  this.$api.post(
                    `${process.env.VUE_APP_SHARED_API}api/collector_trends`,
                    {
                      artwork_id: this.lastId,
                      collector_trends: requestArr,
                    }
                  );
                } else {
                  const clean = (obj) => {
                    for (var propName in obj) {
                      if (
                        obj[propName] === null ||
                        obj[propName] === undefined ||
                        obj[propName] === 0 ||
                        obj[propName] === ""
                      ) {
                        delete obj[propName];
                      }
                    }
                    return obj;
                  };
                  let requestArr = [];
                  localCollectorUpdate.map((obj) => {
                    let o = clean(obj);
                    requestArr.push(o);
                  });
                  if (requestArr[0]) {
                    this.$api.put(
                      `${process.env.VUE_APP_SHARED_API}api/collector_trends/${this.lastId}`,
                      {
                        collector_trends: requestArr,
                      }
                    );
                  }
                }
              } else {
                loader.hide();
                ElNotification({
                  title: "Warning!",
                  message: "Something went wrong, please try later!",
                  type: "warning",
                });
              }
            })
            .catch((e) => {
              loader.hide();
              Object.keys(e.response.data.errors).forEach(function (key) {
                ElNotification({
                  title: "Warning!",
                  message: e.response.data.errors[key][0],
                });
              });
            });
        }
      }
    },
    addTagAsPrimary(id) {
      if (this.postData.primaryTagId === id) {
        this.postData.primaryTagId = 0;
      } else {
        this.postData.primaryTagId = id;
        if (!this.postData.choosenTags.includes(id))
          this.postData.choosenTags.push(id);
      }
    },
    async updatePost() {
      if (this.postData.title === "" || this.postData.slug === "") {
        ElNotification({
          title: "Warning!",
          message: "Mandatory fields must be filled in!",
          type: "warning",
        });
      } else {
        this.$store.dispatch("collectData");
        let isValid =
          this.$route.params.type === "artworks"
            ? await validateForm(this.$route.params.type)
            : true;
        if (!isValid) {
          ElNotification({
            title: "Warning!",
            message: "Mandatory fields must be filled in!",
            type: "warning",
          });
        } else {
          const loader = this.$loading.show();
          this.$store.dispatch("collectData");
          if (this.postData.slug === "" && this.postData.title !== "") {
            ElNotification({
              title: "Warning!",
              message: "Mandatory fields must be filled in!",
              type: "warning",
            });
          } else {
            let isValid =
              this.$route.params.type === "artworks"
                ? await validateForm(this.$route.params.type)
                : true;
            if (!isValid) {
              ElNotification({
                title: "Warning!",
                message: "Mandatory fields must be filled in!",
                type: "warning",
              });
            } else {
              return await updatePost(
                this.$route.params.type,
                this.postData,
                this.choosenTaxonomies,
                this.$cookies.get("bearerToken"),
                this.lastId,
                this.choosenCollectorItems
              )
                .then((res) => {
                  loader.hide();
                  if (res.ok !== false) {
                    ElNotification({
                      title: "Success!",
                      message: `${this.postData.title} successfully updated.`,
                      type: "success",
                    });
                    if (
                      this.$store.state.customFields.artworks
                        .collectorTrendsDeletedIds.length > 0
                    ) {
                      this.$api.delete(
                        `${process.env.VUE_APP_SHARED_API}api/collector_trends/${this.lastId}`,
                        {
                          data: {
                            ids: this.$store.state.customFields.artworks
                              .collectorTrendsDeletedIds,
                          },
                        }
                      );
                    }
                    let localCollectorCreate = [];
                    let localCollectorUpdate = [];
                    this.$store.state.customFields.artworks.collectorTrends &&
                      this.$store.state.customFields.artworks.collectorTrends.map(
                        (collector) => {
                          if (collector.created_at) {
                            localCollectorUpdate.push(collector);
                          } else {
                            localCollectorCreate.push(collector);
                          }
                        }
                      );
                    if (localCollectorCreate.length > 0) {
                      const clean = (obj) => {
                        for (var propName in obj) {
                          if (
                            obj[propName] === null ||
                            obj[propName] === undefined ||
                            obj[propName] === 0 ||
                            obj[propName] === ""
                          ) {
                            delete obj[propName];
                          }
                        }
                        return obj;
                      };
                      let requestArr = [];
                      localCollectorCreate.map((obj) => {
                        let o = clean(obj);
                        requestArr.push(o);
                      });
                      this.$api.post(
                        `${process.env.VUE_APP_SHARED_API}api/collector_trends`,
                        {
                          artwork_id: this.lastId,
                          collector_trends: requestArr,
                        }
                      );
                    } else {
                      const clean = (obj) => {
                        for (var propName in obj) {
                          if (
                            obj[propName] === null ||
                            obj[propName] === undefined ||
                            obj[propName] === 0 ||
                            obj[propName] === ""
                          ) {
                            delete obj[propName];
                          }
                        }
                        return obj;
                      };
                      let requestArr = [];
                      localCollectorUpdate.map((obj) => {
                        let o = clean(obj);
                        requestArr.push(o);
                      });
                      if (requestArr[0]) {
                        this.$api.put(
                          `${process.env.VUE_APP_SHARED_API}api/collector_trends/${this.lastId}`,
                          {
                            collector_trends: requestArr,
                          }
                        );
                      }
                    }
                  } else {
                    loader.hide();
                    ElNotification({
                      title: "Warning!",
                      message: "Something went wrong, please try later!",
                      type: "warning",
                    });
                  }
                })
                .catch((e) => {
                  loader.hide();
                  if (e.response.data.errors) {
                    Object.keys(e.response.data.errors).forEach(function (key) {
                      ElNotification({
                        title: "Warning!",
                        message: e.response.data.errors[key][0],
                      });
                    });
                  } else {
                    ElNotification({
                      title: "Error!",
                      message: e,
                    });
                  }
                });
            }
          }
        }
      }
    },
    openInNewTab() {
      if (this.$route.params.type === "artworks") {
        window.open(
          `${process.env.VUE_APP_FRONT_PREVIEW}artworks/${this.postData.slug}`,
          "_blank"
        );
      }
      if (this.$route.params.type === "techniques") {
        window.open(
          `${process.env.VUE_APP_FRONT_PREVIEW}techniques/${this.postData.slug}`,
          "_blank"
        );
      }
      if (this.$route.params.type === "artists") {
        window.open(
          `${process.env.VUE_APP_FRONT_PREVIEW}artist/${this.postData.slug}`,
          "_blank"
        );
      }
    },
    createTaxs(data) {
      let parentName = data.categoryName;
      const index = this.customTaxonomiesRes.findIndex(
        (category) => category.title === parentName
      );
      if (index !== -1) {
        this.customTaxonomiesRes[index].data.unshift(data.data);
      }
    },
    tagsPropsChange(propChange) {
      if (propChange?.primaryTagId) {
        this.postData.primaryTagId = propChange.primaryTagId;
      }
      if (propChange?.choosenTags) {
        this.postData.choosenTags = propChange.choosenTags;
      }
    },
    tagCreate(tag) {
      this.postData.tags.push(tag);
    },
  },
  mounted() {
    if (this.$route.params.type === "artworks") {
      const loader = this.$loading.show();
      this.$api
        .get(`${process.env.VUE_APP_SHARED_API}api/tags?per_page=15`)
        .then((res) => {
          if (res.data.current_page) {
            this.postData.tags = res.data.data;
          }
        });
      this.customTaxonomies.map((tax, i) => {
        this.$api
          .get(`${process.env.VUE_APP_SHARED_API}api/${tax}?per_page=15`)
          .then((res) => {
            if (res.data.current_page) {
              loader.hide();
              this.customTaxonomiesRes.push({
                title: tax,
                id: i,
                data: res.data.data,
                choosenTax: [],
              });
            }
          });
      });
    }
  },
  unmounted() {
    this.$store.dispatch("resetState");
  },
};
</script>

<style lang="scss" scoped>
.navigation-title-section-content {
  p {
    margin-bottom: 0;
    text-transform: capitalize;
  }
}

.single_post_main_box {
  padding: 25px;
  background-color: #f2f2f2;
  border-radius: 5px;
  height: 100%;
}

.single_post_box {
  background-color: #f2f2f2;
  padding: 25px;
  margin-bottom: 25px;
  border-radius: 5px;

  .single_post_box__h3 {
    font-size: 20px;
    margin-bottom: 20px;
    text-transform: capitalize;
  }

  .featured_image_single {
    max-width: 400px;
    margin: 0 auto;
  }
}

.category_checkbox:active,
.category_checkbox:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.categories_box {
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
  height: 150px;
  overflow-y: scroll;
}

.single_category__div {
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 15px;

  .category_checkbox {
    -webkit-appearance: none;
    background-color: #fafafa;
    border: 1px solid #cacece;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 9px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    margin-right: 10px;
  }

  label {
    font-size: 14px;
    cursor: pointer;
  }

  .category_checkbox:checked {
    background-color: #e9ecee;
    border: 1px solid #42b983;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
      inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    color: #99a1a7;
  }

  .category_checkbox:checked:after {
    content: "\2714";
    font-size: 14px;
    position: absolute;
    top: 0px;
    left: 3px;
    color: #42b983;
  }
}

.buttons_section {
  text-align: right;
}
.tip_tap_editor {
  max-height: 650px;
  height: 100%;
}
.acf-fields {
  min-height: 150px;
  margin-top: 30px;
  // margin-bottom: 30px;
}
.row-wrapper {
  position: relative;
}
.delete-row {
  position: absolute;
  top: -5px;
  right: -5px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: red;
  border-radius: 50%;
  align-items: center;
}
.delete-row > svg {
  color: white;
  fill: white;
}
.required-field {
  color: red;
  font-weight: bold;
}
.tags-list {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style-type: none;
  max-height: 150px;
  height: 100%;
  overflow-y: scroll;
}
.tags-list li {
  padding-left: 10px;
}
.tag-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}
.tag-list-item svg {
  width: 18px;
  height: 18px;
  margin-left: 30px;
  cursor: pointer;
  margin-right: 10px;
}
.active-star svg {
  color: #f79c34;
}
</style>
