<template>
  <div class="navigation-title-section">
    <div class="navigation-title-section-content">Edit order</div>
  </div>
  <div class="order_data">
    <h2>Order #{{ otherRest.id }} details</h2>
    <p>
      Payment via {{ otherRest.payment_method }}. Paid on
      {{ moment(otherRest.created_at) }}.
    </p>
    <div class="row">
      <div class="order_details col-12 col-md-11">
        <div class="col-12 col-md-3">
          <h3>General</h3>
          <small class="mt-3">Date created:</small>
          <p>{{ moment(otherRest.created_at) }}</p>
          <small class="mt-3">Status:
            <span v-if="otherRest.status == 'refund' || otherRest.status == 'completed'
      ">{{ otherRest.status }}</span></small>
          <el-select v-model="order.status" class="m-2" placeholder="Select" size="large" v-if="otherRest.status != 'refund' && otherRest.status != 'completed'
      ">
            <el-option v-for="item in statuses" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </div>
        <div class="col-12 col-md-3 flex-equal">
          <font-awesome-icon :icon="['fa', 'edit']" @click="() => (showBillingForm = !showBillingForm)"
            class="icon-controller" />
          <h3>Billing</h3>
          <small class="mt-3"><b>Address:</b></small>
          <small>{{ order["billing[address]"] }}</small>
          <small class="mt-3"><b>Email:</b></small>
          <small><a href="mailto:">{{ order["contact[email]"] }}</a></small>
          <form v-if="showBillingForm" @submit.prevent="updateOrder">
            <small class="mt-3 mb-3"><b>Edit data:</b></small>
            <div class="row mb-2">
              <div class="col-6">
                <label for="phone">Phone <span class="req">*</span></label>
                <el-input v-model="order['billing[phone]']" placeholder="Please enter phone number" min="8" />
              </div>
              <div class="col-6">
                <label for="phone">Zip Code <span class="req">*</span></label>
                <el-input v-model="order['billing[zip_code]']" placeholder="Please enter zip code" />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-6">
                <label for="phone">State <span class="req">*</span></label>
                <el-input v-model="order['billing[state]']" placeholder="Please enter state" />
              </div>
              <div class="col-6">
                <label for="phone">Country <span class="req">*</span></label>
                <el-input v-model="order['billing[country]']" placeholder="Please enter country" />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-6">
                <label for="phone">Apartment <span class="req">*</span></label>
                <el-input v-model="order['billing[apartment]']" placeholder="Please enter apartment" />
              </div>
              <div class="col-6">
                <label for="phone">Address <span class="req">*</span></label>
                <el-input v-model="order['billing[address]']" placeholder="Please enter address" />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12">
                <label for="phone">Address 2</label>
                <el-input v-model="order['billing[address2]']" placeholder="Please enter apartment" />
              </div>
            </div>
          </form>
        </div>
        <div class="flex-equal col-12 col-md-3">
          <font-awesome-icon :icon="['fa', 'edit']" @click="() => (showShippingForm = !showShippingForm)"
            class="icon-controller" />
          <h3>Shipping</h3>
          <small class="mt-3"><b>Address:</b></small>
          <small>{{ order["shipping[address]"] }}</small>
          <form v-if="showShippingForm" @submit.prevent="updateOrder">
            <small class="mt-3 mb-3"><b>Edit data:</b></small>
            <div class="row mb-2">
              <div class="col-6">
                <label for="phone">Phone <span class="req">*</span></label>
                <el-input v-model="order['shipping[phone]']" placeholder="Please enter phone number" min="8" />
              </div>
              <div class="col-6">
                <label for="phone">Zip Code <span class="req">*</span></label>
                <el-input v-model="order['shipping[zip_code]']" placeholder="Please enter zip code" />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-6">
                <label for="phone">State <span class="req">*</span></label>
                <el-input v-model="order['shipping[state]']" placeholder="Please enter state" />
              </div>
              <div class="col-6">
                <label for="phone">Country <span class="req">*</span></label>
                <el-input v-model="order['shipping[country]']" placeholder="Please enter country" />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-6">
                <label for="phone">Apartment <span class="req">*</span></label>
                <el-input v-model="order['shipping[apartment]']" placeholder="Please enter apartment" />
              </div>
              <div class="col-6">
                <label for="phone">Address <span class="req">*</span></label>
                <el-input v-model="order['shipping[address]']" placeholder="Please enter address" />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12">
                <label for="phone">Address 2</label>
                <el-input v-model="order['shipping[address2]']" placeholder="Please enter apartment" />
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-md-2 flex-equal">
          <font-awesome-icon :icon="['fa', 'edit']" @click="() => (showContactForm = !showContactForm)"
            class="icon-controller" />
          <h3>Contact</h3>
          <small class="mt-3"><b>Email:</b></small>
          <small><a href="mailto:">{{ order["contact[email]"] }}</a></small>
          <form v-if="showContactForm" @submit.prevent="updateOrder">
            <small class="mt-3 mb-3"><b>Edit data:</b></small>
            <div class="row mb-2">
              <div class="col-6">
                <label for="phone">Name <span class="req">*</span></label>
                <el-input v-model="order['contact[name]']" placeholder="Please enter name" />
              </div>
              <!-- <div class="col-6">
                <label for="phone">First Name <span class="req">*</span></label>
                <el-input
                  v-model="order['contact[first_name]']"
                  placeholder="Please enter first name"
                />
              </div> -->
              <!-- <div class="col-6">
                <label for="phone">Last Name <span class="req">*</span></label>
                <el-input
                  v-model="order['contact[last_name]']"
                  placeholder="Please enter last name"
                />
              </div> -->
            </div>
            <div class="row mb-2">
              <div class="col-12">
                <label for="phone">Email</label>
                <el-input v-model="order['contact[email]']" placeholder="Please enter email address" />
              </div>
            </div>
          </form>
        </div>
        <div v-if="otherRest.status === 'completed' ||
      trackingNumber != '' ||
      order.status === 'completed'
      " class="col-12 col-md-2 flex-equal">
          <h3>Tracking number</h3>
          <form @submit.prevent="updateOrder">
            <el-input class="mt-2" v-model="trackingNumber" placeholder="Please enter tracking number" />
          </form>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-4" v-if="otherRest.status != 'refund' && otherRest.status != 'completed'">
      <el-button class="button_style_1 mt-2" @click="updateOrder">
        Update
      </el-button>
      <el-button style="margin: 0" class="button_style_2 mt-2" @click="refundOrder">
        Refund
      </el-button>
    </div>
  </div>

  <div v-if="otherRest?.artworks?.length > 0" class="order_data mt-4">
    <OrderReport :orderData="otherRest.artworks" />
  </div>
</template>

<script>
import moment from "moment";
import { ElNotification } from "element-plus";
import { ElMessageBox } from "element-plus";
import OrderReport from "./components/OrderReport.vue";

export default {
  name: "Edit Order",
  components: {
    OrderReport,
  },
  data() {
    return {
      otherRest: {},
      order: {
        status: "",
        "shipping[phone]": "",
        "shipping[zip_code]": "",
        "shipping[state]": "",
        "shipping[country]": "",
        "shipping[city]": "",
        "shipping[apartment]": "",
        "shipping[address]": "",
        "shipping[address2]": "",
        "billing[phone]": "",
        "billing[zip_code]": "",
        "billing[state]": "",
        "billing[country]": "",
        "billing[city]": "",
        "billing[apartment]": "",
        "billing[address]": "",
        "billing[address2]": "",
        "contact[name]": "",
        // "contact[last_name]": "",
        "contact[name]": "",
        "contact[email]": "",
      },
      nonRequired: ["shipping[address2]", "billing[address2]"],
      statuses: [
        { value: "created", label: "created" },
        { value: "completed", label: "completed" },
        // { value: "delivered", label: "delivered" },
        // { value: "refound", label: "refound" },
        // { value: "failed", label: "failed" },
      ],
      showBillingForm: false,
      showShippingForm: false,
      showContactForm: false,
      trackingNumber: "",
      prevStatus: "",
    };
  },
  mounted() {
    if (this.$route.params.id) {
      const loader = this.$loading.show();
      this.$api
        .get(
          `${process.env.VUE_APP_SHARED_API}api/orders/${this.$route.params.id}`
        )
        .then((res) => {
          loader.hide();
          this.otherRest = res.data;
          this.order = {
            status: res.data.status || "",
            "shipping[phone]": res.data.shipping_address.phone || "",
            "shipping[zip_code]": res.data.shipping_address.zip_code || "",
            "shipping[state]": res.data.shipping_address.state || "",
            "shipping[country]": res.data.shipping_address.country || "",
            "shipping[city]": res.data.shipping_address.city || "",
            "shipping[apartment]": res.data.shipping_address.apartment || "",
            "shipping[address]": res.data.shipping_address.address || "",
            "shipping[address2]": res.data.shipping_address.address2 || "",
            "billing[phone]": res.data.billing_address.phone || "",
            "billing[zip_code]": res.data.billing_address.zip_code || "",
            "billing[state]": res.data.billing_address.state || "",
            "billing[country]": res.data.billing_address.country || "",
            "billing[city]": res.data.billing_address.city || "",
            "billing[apartment]": res.data.billing_address.apartment || "",
            "billing[address]": res.data.billing_address.address || "",
            "billing[address2]": res.data.billing_address.address2 || "",
            "contact[name]":
              res.data.contact_information.name || "",
            // "contact[last_name]": res.data.contact_information.last_name || "",
            "contact[email]": res.data.contact_information.email || "",
            "contact[name]": res.data.contact_information.name || "",
          };
          this.trackingNumber = res.data.tracking_number || "";
          if (res.data.status !== "refund" && res.data.status !== "completed")
            this.prevStatus = res.data.status;
        })
        .catch((e) => {
          ElNotification({
            title: "Warning!",
            message: "Something went wrong, please try later",
            type: "warning",
          });
        });
    }
  },
  methods: {
    async updateOrder() {
      const loader = this.$loading.show();
      let runUpdate = true;
      for (const [key, value] of Object.entries(this.order)) {
        console.log(key, value)
        if (!this.nonRequired.includes(key) && value === "") {
          loader.hide();
          ElNotification({
            title: "Warning!",
            message: "Mandatory fields are required!",
            type: "warning",
          });
          return (runUpdate = false);
        }
      }
      if (runUpdate) {
        let myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append(
          "Authorization",
          `Bearer ${this.$cookies.get("bearerToken")}`
        );
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        let urlencoded = new URLSearchParams();
        for (const [key, value] of Object.entries(this.order)) {
          urlencoded.append(key, value);
        }
        this.trackingNumber &&
          urlencoded.append("tracking_number", this.trackingNumber);
        let requestOptions = {
          method: "PUT",
          headers: myHeaders,
          body: urlencoded,
          redirect: "follow",
        };
        await fetch(
          `${process.env.VUE_APP_SHARED_API}api/orders/${this.$route.params.id}`,
          requestOptions
        )
          .then((response) => {
            if (response.ok === true) {
              return {
                status: true,
              };
            } else {
              return response.json();
            }
          })
          .then((result) => {
            if (result.status === true) {
              ElNotification({
                title: "Success!",
                message: "Success, updated successfully!",
                type: "success",
              });
              loader.hide();
            } else {
              loader.hide();
              ElNotification({
                title: "Warning!",
                message: result.errors.status[0],
                type: "warning",
              });
            }
          })
          .catch((error) => {
            loader.hide();
            ElNotification({
              title: "Warning!",
              message: "Something went wrong, please try later",
              type: "warning",
            });
          });
      }
    },
    moment: function (date) {
      return moment(date).format("YYYY/MM/DD @ h:mm:ss a");
    },
    async refundOrder() {
      ElMessageBox({
        title: "Are you sure?",
        message: "This order will be refunded.",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "Loading...";
            done();
          } else {
            done();
          }
        },
      }).then(async (action) => {
        let myHeaders = new Headers();
        const loader = this.$loading.show();
        myHeaders.append("Accept", "application/json");
        myHeaders.append(
          "Authorization",
          `Bearer ${this.$cookies.get("bearerToken")}`
        );
        let requestOptions = {
          method: "POST",
          headers: myHeaders,
        };
        await fetch(
          `${process.env.VUE_APP_SHARED_API}api/order-refund/${this.$route.params.id}`,
          requestOptions
        )
          .then(async (response) => {
            const data = await response.json();
            // please give me an example of success refunding, payload is just text
            ElNotification({
              title: "Info",
              message: data?.message || data,
              type: "info",
            });
            loader.hide();
          })
          .catch((e) => {
            loader.hide();
            ElNotification({
              title: "Warning!",
              message: "Something went wrong, please try later",
              type: "warning",
            });
          });
      });
    },
  },
  computed: {
    returnUri() {
      return process.env.VUE_APP_SHARED_API;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-controller {
  position: absolute;
  transform: translate(-50%, 10px);
  right: 50%;
  top: 10px;
  cursor: pointer;
  z-index: 1;
}

.order_details label {
  display: block;
  padding: 0 0 3px;
  cursor: pointer;
  color: #777;
  font-size: 13px;
  line-height: 1.5;
}

@media (max-width: 550px) {
  .order_details {
    flex-wrap: wrap;
  }
}

.flex-equal {
  // width: 30%;
  // flex: 1 0 33%;
  position: relative;
}

.mr-3 {
  margin-right: 13px;
}

.mt-3 {
  display: block;
}

.order_details {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.order_data {
  padding: 23px 24px 12px;
  color: #555;
  line-height: 1.4;
  // font-size: 13px;
  position: relative;
  max-width: 79vw;
  border: 1px solid #c3c4c7;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04);
  background: #fff;
}

.order_data h2 {
  margin: 0;
  font-size: 21px;
  font-weight: 400;
  line-height: 1.2;
  text-shadow: 1px 1px 1px #fff;
  padding: 0;
}

.order_data h3 {
  margin: 0;
  margin: 1.33em 0 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.2;
  text-shadow: 1px 1px 1px #fff;
  padding: 0;
}

.order_data p {
  margin: 0;
  font-weight: 400;
  line-height: 1.6em;
  font-size: 16px;
}

.req {
  color: red;
}

.serach-wrapper {
  display: flex;
  max-width: 300px;
}

.new_menu_button_sec {
  position: absolute;
  right: 15px;

  button {
    background-color: white;
    text-transform: uppercase;
    color: #338973;
  }
}

.users_list {
  display: flex;
  flex-wrap: wrap;
  padding-right: 30px;
}

.user {
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3);
  text-align: center;
  padding: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
  width: 23.6034%;

  .user_image {
    text-align: center;
    padding-bottom: 30px;

    img {
      max-width: 150px;
    }
  }

  .user_content {
    h3 {
      font-size: 24px;
    }

    p {
      margin-bottom: 30px;
    }

    button {
      background-color: #338973;
      text-transform: uppercase;
      color: white;
      border: 1px solid #338973;
      transition: all 0.4s ease;
    }

    button:hover {
      background-color: #fff;
      color: #338973;
    }
  }
}

.user:nth-child(4) {
  margin-right: 0;
}

.languages_switcher {
  .languages_switcher__p {
    display: inline-block;
    margin: 0 5px;

    a {
      display: inline-block;
      background-color: transparent;
      color: #338973;
      border: 1px solid #338973;
      border-bottom: 0;
      text-align: center;
      padding-top: 5px;
      width: 50px;
      font-size: 18px;
      text-decoration: none;
      text-transform: uppercase;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
}

.new_menu_button_sec {
  position: absolute;
  right: 15px;

  button {
    background-color: white;
    text-transform: uppercase;
    color: #1f3c2a;
  }
}

.items {
  padding-left: 30px;
  padding-right: 30px;

  .items_form_title {
    border-radius: 0px;
    background-color: #1f3c2a;
    color: white;
    padding: 5px 25px;
    text-align: left;

    .items_form_title__columns {
      padding: 12px;
      padding-left: 0;
    }

    p {
      margin-bottom: 0;
    }
  }

  .item_list {
    padding: 5px 25px;
    border: 2px solid #1f3c2a;
    border-top: none;
    margin-top: -15px;
    padding-top: 25px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    .single_row_item {
      border-bottom: 1px solid #ccc;
      align-content: center;
      align-items: center;
      padding: 15px 0;
      margin: 0;

      h5 {
        font-weight: normal;
        line-height: 30px;
      }

      .single_row_item__p {
        color: #338973;
        display: inline-block;
        cursor: pointer;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 0;

        a {
          color: #338973;
          text-decoration: none !important;
        }
      }

      .add_or_edit_item_language {
        display: flex;

        div {
          flex: 0 0 10%;
        }
      }

      .single_row_item__p:after {
        content: "|";
        margin: 0 5px;
      }

      .single_row_item__p:last-child:after {
        content: "";
      }

      .language_icon {
        font-size: 14px;
      }

      .language_icon.language_icon__add {
        color: #338973;
      }

      .language_icon.language_icon__edit {
        color: #9c27b0;
      }
    }

    .single_row_item:last-child {
      border-bottom: none;
    }

    .single_row_item>* {
      padding: 0;
    }
  }
}

.dialog_category_p {
  font-size: 18px;
}

.status {
  display: inline-flex;
  line-height: 2.5em;
  color: #777;
  background: #e5e5e5;
  border-radius: 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin: -0.25em 0;
  cursor: inherit !important;
  white-space: nowrap;
  max-width: 100%;
}

.status>span {
  margin: 0 1em;
  overflow: hidden;
  font-size: 13px;
  text-overflow: ellipsis;
}

.status-created {
  background: #c6e1c6;
  color: #5b841b;
}

.status-completed {
  background: #c8d7e1;
  color: #2e4453;
}

@media (max-width: 992px) {
  .order_data {
    max-width: 95vw;
    margin-left: 10px;
  }
}
</style>
